import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_tab_pane = _resolveComponent("n-tab-pane")!
  const _component_n_tabs = _resolveComponent("n-tabs")!
  const _component_SearchBar = _resolveComponent("SearchBar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_n_tabs, {
      type: "line",
      value: _ctx.active,
      "onUpdate:value": _ctx.handleUpdateValue
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabsItems, (item) => {
          return (_openBlock(), _createBlock(_component_n_tab_pane, {
            name: item.name,
            tab: item.tab,
            key: item.name
          }, null, 8, ["name", "tab"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "onUpdate:value"]),
    _createElementVNode("section", null, [
      _createVNode(_component_SearchBar, { onSearch: _ctx.onSearch }, null, 8, ["onSearch"])
    ])
  ]))
}