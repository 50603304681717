import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-margin-left-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flash_icon = _resolveComponent("flash-icon")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_button = _resolveComponent("n-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex",
    onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onKeyup && _ctx.onKeyup(...args)), ["enter"]))
  }, [
    _createVNode(_component_n_input, {
      round: "",
      placeholder: "",
      value: _ctx.value,
      "onUpdate:value": _ctx.onUpate,
      size: "large"
    }, {
      suffix: _withCtx(() => [
        _createVNode(_component_n_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_flash_icon)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "onUpdate:value"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_n_button, {
        strong: "",
        secondary: "",
        onClick: _ctx.onSearch,
        size: "large"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("搜索")
        ])),
        _: 1
      }, 8, ["onClick"])
    ])
  ], 32))
}