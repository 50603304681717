
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseHeader',
  data () {
    return {
      items: [
        {
          name: '开源应用',
          url: '/open-source-app'
        },
        {
          name: '手脚架模板',
          url: '/cli-template'
        },
        {
          name: '社区',
          url: '/community'
        }
      ]
    }
  },
  computed: {
    isHomePage (): boolean {
      return this.$route.path === '/'
    }
  }
})
