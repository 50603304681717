
import { defineComponent } from 'vue'
import NavItem from './NavItem.vue'
// eslint-disable-next-line @typescript-eslint/no-var-requires
import navItemsData from '@/data/nav-items.json'

export default defineComponent({
  name: 'NavItems',
  components: {
    NavItem
  },
  data () {
    return {
      items: navItemsData
    }
  },
  methods: {
    onClick (url: string) {
      const $win = window.top || window
      $win.location.href = url
    }
  }
})
