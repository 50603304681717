import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/open-source-app',
    name: 'OpenSourceApp',
    component: () => import('../views/OpenSourceApp.vue')
  },
  {
    path: '/cli-template',
    name: 'CliTemplate',
    component: () => import('../views/CliTemplate.vue')
  },
  {
    path: '/community',
    name: 'Community',
    component: () => import('../views/Community.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
