
import { defineComponent } from 'vue'
import JuHeSearch from '@/components/JuHeSearch.vue'
import NavItems from '@/components/NavItems.vue'
import BaseHeader from '@/components/base/BaseHeader.vue'

export default defineComponent({
  name: 'Home',
  components: {
    JuHeSearch,
    NavItems,
    BaseHeader
  }

})
