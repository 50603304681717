import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavItem = _resolveComponent("NavItem")!
  const _component_n_space = _resolveComponent("n-space")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_n_space, { size: [30, 40] }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, idx) => {
          return (_openBlock(), _createBlock(_component_NavItem, {
            key: idx,
            item: item,
            onClick: ($event: any) => (_ctx.onClick(item.url))
          }, null, 8, ["item", "onClick"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}