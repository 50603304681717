
import { defineComponent } from 'vue'
import SearchBar from '@/components/SearchBar.vue'
export default defineComponent({
  name: 'ComponentName',
  components: {
    SearchBar
  },
  data () {
    const tabsItems = [
      {
        name: 'baidu',
        tab: '百度',
        url: 'https://www.baidu.com/s?word='
      },
      {
        name: 'github',
        tab: 'github',
        url: 'https://github.com/search?q='
      },
      {
        name: 'juejin',
        tab: '掘金',
        url: 'https://juejin.cn/search?query='
      },
      {
        name: 'google',
        tab: '谷歌',
        url: 'https://www.google.com.hk/search?q='
      },
      {
        name: 'npm',
        tab: 'npm',
        url: 'https://www.npmjs.com/search?q='
      },
      {
        name: 'pub.dev',
        tab: 'pub.dev',
        url: 'https://pub.dev/packages?q='
      },
      // {
      //   name: 'docker hub',
      //   tab: 'Docker Hub',
      //   url: 'https://registry.hub.docker.com/search?q='
      // },
      {
        name: 'scoop',
        tab: 'scoop',
        url: 'https://scoop.sh/#/apps?q='
      },
      {
        name: 'segmentfault',
        tab: '思否',
        url: 'https://segmentfault.com/search?q='
      }
    ]

    return {
      tabsItems,
      active: 'baidu'
    }
  },
  methods: {
    onSearch (kw: string) {
      const $win = window.top || window
      const index: number = this.tabsItems.findIndex((item) => {
        return item.name === this.active
      })
      const url: string = this.tabsItems[index].url
      $win.location.href = `${url}${kw}`
    },
    handleUpdateValue (value: string) {
      this.active = value
      localStorage.setItem('ACTIVE_TAB', value)
    }
  },
  mounted () {
    const active = localStorage.getItem('ACTIVE_TAB')
    if (active) {
      this.active = active
    }
  }
})
