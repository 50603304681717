import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav-item" }
const _hoisted_2 = { class: "top d-flex d-col-center" }
const _hoisted_3 = { class: "item-icon" }
const _hoisted_4 = { class: "d-padding-left-10" }
const _hoisted_5 = { class: "d-font-18 text-nowrap" }
const _hoisted_6 = { class: "btm d-padding-top-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_image = _resolveComponent("n-image")!
  const _component_n_ellipsis = _resolveComponent("n-ellipsis")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_n_image, {
          height: "34",
          src: _ctx.item.image,
          "img-props": {'style': 'max-width: 100%;'},
          "preview-disabled": true
        }, null, 8, ["src"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.item.title), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_6, [
      _createVNode(_component_n_ellipsis, {
        style: {"max-width":"190px","word-break":"break-all"},
        "line-clamp": "2"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.item.content), 1)
        ]),
        _: 1
      })
    ])
  ]))
}