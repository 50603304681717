/*
 * @FilePath: /dev-nav/src/lib/NaiveUI.ts
 * @Description:
 */

import {
  // create naive ui
  create,
  // component
  NButton,
  NInput,
  NSpace,
  NIcon,
  NImage,
  NEllipsis,
  NTabs,
  NTabPane
} from 'naive-ui'

const naive = create({
  components: [NButton, NInput, NSpace, NIcon, NImage, NEllipsis, NTabs,
    NTabPane]
})

export default naive
