
import { FlashOutline as FlashIcon } from '@vicons/ionicons5'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SearchBar',
  components: {
    FlashIcon
  },
  data () {
    return {
      value: ''
    }
  },
  methods: {
    onSearch () {
      this.$emit('search', this.value)
    },
    onUpate (value: string) {
      this.value = value
    },
    onKeyup () {
      this.onSearch()
    }
  }
})
